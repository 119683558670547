interface IAddress {
  city: string
  state_district: string
  state: string
  postcode: string
  country: string
  country_code: string
}

interface GeoJson {
  type: string,
  coordinates: Array<number[]>
}
interface INominatimResponse {
  place_id: number
  licence: string
  osm_type: string
  osm_id: number
  lat: string
  lon: string
  class: string
  type: string
  place_rank: number
  importance: number
  addresstype: string
  name: string
  display_name: string
  boundingbox: string[],
  geojson: GeoJson
  address: IAddress
}

export interface INominatimEntity {
  title: string
  addressLocality: string
  streetAddress: string
  location: string
  boundingbox: string[]
  geojson: GeoJson
}

export const useNominatimSearch = async(
  url: string,
  searchValue: string
): Promise<INominatimEntity[]> => {
  const result: Set<INominatimEntity> = new Set()

  const response = await fetch(`${url}/search?q=${searchValue}&format=json&limit=5&addressdetails=1&polygon_geojson=1`)
  if (!response?.ok) return []

  const data: INominatimResponse[] = await response?.json()
  data?.forEach(({
    name,
    display_name,
    lat,
    lon,
    boundingbox,
    address,
    geojson
  }) => {
    result.add({
      title: name || display_name,
      location: `${lat}, ${lon}`,
      addressLocality: address.city || address.state,
      streetAddress: display_name,
      boundingbox,
      geojson
    })
  })

  return Array.from(result)
}
